.project-nav {
    margin-left: 12rem;
    width: calc(100% - 12rem);
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.project-nav .prev-project,
.project-nav .next-project {
    font-size: 20px;
    cursor: pointer;
}

@media screen and (max-width: 768px),
             screen and (max-height: 500px) {
    .project-nav {
        margin-left: 0;
        width: 100%;
    }
}