footer {
    margin-left: 12rem;
    width: calc(100% - 12rem);
    background-color: #333; /* Tło stopki */
    color: white; /* Kolor tekstu w stopce */
    padding: 2rem; /* Odstęp wewnętrzny */
    text-align: center; /* Wyśrodkowanie tekstu */
    flex-shrink: 1; /* Zapewnienie, że footer nie zmniejszy się */
}

@media screen and (max-width: 768px),
             screen and (max-height: 500px) {
    footer {
        margin-left: 0;
        width: 100%;
    }
}

.footer-content {
    display: flex; /* Umożliwienie horyzontalnego układu */
    flex-direction: column; /* Ułożenie w kolumnie */
    align-items: center; /* Wyśrodkowanie */
    justify-content: stretch;
}

.footer-links {
    display: flex; /* Umożliwienie horyzontalnego układu */
    gap: 15px; /* Odstęp między ikonami */
    margin-bottom: 1rem; /* Odstęp pod ikonami */
}

.footer-links a {
    color: white; /* Kolor linków */
    text-decoration: none; /* Usunięcie podkreślenia */
}

.footer-links svg {
    height: 50px;
    width: 50px;
}
.footer-address-subcontainer {
    display: flex;
    flex-direction: row;
}

.footer-address-container {
    display: flex;
    flex-direction: column;
}

.footer-address-container * {
    margin-top: 0.5rem;
}

.footer-address-subcontainer * {
    margin-top: 0;
}

.footer-justified-container {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    justify-content: space-between;
    align-items: flex-end;
}

.footer-title {
    font-weight: 700;
}

@media screen and (max-width: 768px),
             screen and (max-height: 500px) {
    .footer-justified-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-content {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
@media screen and (max-width: 320px) {
    footer {
    padding: 1rem;
}
}