.panel {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    aspect-ratio : 1 / 1;
}

.panel img {
    width: 100%;
    height: 100%; /* Zdjęcie wypełnia resztę miejsca poza paskiem */
    object-fit: cover;
    filter: saturate(30%); /* Efekt wyblaknięcia */
    transition: filter 0.3s ease; /* Płynne przejście przy najeżdżaniu */
}

.info-bar-header {
    transform: translate(0%, -50%);
    width: 100%;
    text-align: left;
}

.info-bar-header h2 {
    display: block;
    color: white;
}
.czary-mary {
    position: absolute;
    bottom: 0;
    left: 0;
}
.panel .info-bar {
    position: absolute;
    width: 100%;
    height: min-content;
    bottom: 0;
    background-color: #CCCDCD;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-left: 6%;
    padding-right: 6%;
    font-size: 16px;
}
.panel .info-bar-content {
    transform: translate(0%, -0.75rem);
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.info-item {
    text-align: center;
    flex-shrink: 1;
    overflow: hidden;
    max-width: 40%;
}
.info-item span {
    display: block;
}

.info-item .value {
    text-align: left;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 17px;
    font-weight: bold;
    color: white; /* Jaśniejszy kolor dla wartości */
}

.info-item .category-field {
    font-size: 12px;
    font-family: "acumin-variable",sans-serif;
    font-variation-settings: 'wght' 300, 'wdth' 67.5, 'slnt' 0;
    text-align: left;
    color: white
}
.panel:hover img {
    filter: saturate(100%); /* Normalna jasność po najechaniu */
}