.base {
    width: 100%;
    height: 100%;
}

p {
    color: #303030;
}
.main_content {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.section {
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    margin-left: 12rem;
    width: calc(100% - 12rem);
    align-items: center;
    text-align: center;
    font-size: 40px;
    color: white;
}

.section_text {
    min-height: 100vh;
    height: fit-content;
    position: relative;
    margin-left: 12rem;
    width: calc(100% - 12rem);
    display: grid;
    grid-template-columns: 1fr 3fr 0.5fr; /* Układ z lewej dużą kolumną i z prawej szerszą sekcją */
    gap: 20px;
    padding: 20px;
    overflow-x: visible;
    color: white;
    background-image: url('/public/background.svg');
    background-size: 100%;
    background-attachment: scroll;
}

.section_full {
    min-height: 100vh; /* Każda sekcja zajmuje 100% wysokości okna */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 40px;
    color: white;
}


.container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Wysokość kontenera */
    overflow: hidden; /* Ukrycie nadmiaru */
}

.container img {
    vertical-align: top;
    width: 100%;
    height: 100%; /* Zdjęcie wypełnia cały kontener */
    object-fit: cover; /* Zachowanie proporcji zdjęcia */
}

.info_bar {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #303030; /* Półprzezroczysty czarny pasek */
    color: white;
    padding: 25px; /* Odstęp wewnętrzny */
    width: auto; /* Szerokość paska */
    height: 126px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5); /* Lekki cień */
    display: flex;
    /*grid-template-columns: 1fr 1fr 1fr 1fr;*/
    gap: 50px; /* Odstęp między elementami */
    align-items: center; /* Wyrównanie elementów do środka w pionie */
    justify-content: space-between;
}



.info_item {
    display: flex; /* Flexbox dla ułożenia etykiety i wartości w pionie */
    flex-direction: column; /* Etykieta nad wartością */
    align-items: flex-start; /* Wyrównanie do lewej */
    gap: 15px;
    overflow: hidden;
    max-width: 100%;
    height: max-content;
}

.info_item .label {
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    text-align: left;
}

.info_item .value {
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    text-align: left;
}

.text_overlay {
    position: absolute;
    font-size: 41px;
    max-width: 50%;
    top: 50%;
    transform: translate(0%, -50%);
    right: 10%; 
    font-weight: bold;
    color: white; /* Kolor tekstu */
}

.left_section {
    height: 100%;
    min-height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-wrap: nowrap;
    writing-mode: vertical-rl;
    font-weight: bold;
}

.text_vr {
    transform: rotate(-180deg);
    font-weight: 800;
    font-size: 100px;
}
.arrow {
    position: fixed;
    left: calc(12rem + 1rem);
    top: 2rem;
    z-index: 99;
    width: 2rem;
    height: 2rem;
    overflow: visible;
}
.arrow svg {
    margin-top: 0.75rem;
    transform: translate(0, -50%);
}
@media screen and (max-width: 768px),
             screen and (max-height: 500px){  

    .info_bar {
        width: 100%;
        padding: 20px;
        display: grid;
        align-items: unset;
        grid-template-columns: 1fr 1fr;
        height: auto;
        max-height: fit-content;
    }

    .section {
        margin-left: 0;
        width: 100%;
        max-height: 75vh;
    }
    .section_full {
        max-height: 75vh
    }

    .section_text {
        min-height: unset;
        height: fit-content;
        position: relative;
        margin-left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 30px 0 0;
        overflow-x: visible;
        color: white;
        background-image: url('/public/background.svg');
        background-size: 100%;
        background-attachment: scroll;
        gap: 0;
    }
    .left_section {
        writing-mode: unset;
    }
    .text_vr {
        transform: unset;
        font-size: 64px;
    }
    .arrow {
        left: 10px;
        top: 3rem;
        margin-top: 10px;
    }
    .arrow svg {
        margin-top: unset;
        transform: unset;
    }
}

.right_section {
    height: 100%;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about p {
    color: #222222;
    font-size: 20px;
    line-height: 36px;
    text-align: justify;
    display: flex;
}
.st0 {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 6px;
}



@media screen and (max-width: 1020px){
    .info_bar {
        gap: 2rem;
    }

    .text_overlay {
        max-width: 80%;
    }
}
@media screen and (max-width: 870px) {
    .info_bar {
        gap: 0.75rem;
        padding-left: 8px;
        padding-right: 8px;
    }
}
@media screen and (max-width: 450px) {
    .text_vr {
        font-size: 48px;
    }
}
.section_full:first-child {
    max-height: 100vh;
}


@media (orientation: landscape) {
    .info_bar {
        display: flex;
    }
}