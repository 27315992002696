.team-member-panel {
    max-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    aspect-ratio : 1 / 1;
}

.team-member-panel img {
    width: 100%;
    height: 100%; /* Zdjęcie wypełnia resztę miejsca poza paskiem */
    object-fit: cover;
    filter: saturate(55%); /* Efekt wyblaknięcia */
    transition: filter 1s ease; /* Płynne przejście przy najeżdżaniu */
}

.member-info-bar {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 3% 6%;
    font-size: 16px;
    background-color: black;
}
.team-member-name {
    display: block;
    width: 100%;
    color: white;
    text-align: end;
}
.panel:hover img {
    filter: saturate(100%); /* Normalna jasność po najechaniu */
}