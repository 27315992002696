.error-container {
    position: fixed;
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    align-items: center;
}

.error-container * {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}