.background {
    height: 100vh;
    width: 100vw;
    background-color: white;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
}

.gif {
    position: relative;
    margin: auto;
}