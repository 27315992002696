.main-content {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #e8e8e8;
    background-image: url('/public/background.svg');
    background-repeat: repeat-y;
    background-size: calc(100% - 12rem);
    background-attachment: scroll;
    background-position: 12rem 100vh;
}


.section {
    min-height: 100vh;
    height: fit-content;
    position: relative;
    margin-left: 12rem;
    width: calc(100% - 12rem);
    display: grid;
    grid-template-columns: 1fr 3fr 0.5fr; /* Układ z lewej dużą kolumną i z prawej szerszą sekcją */
    gap: 20px;
    padding: 20px;
    overflow-x: visible;
}

.left-section {
    width: 100%; /* Adjust this to your desired width */
    height: 100%; /* Make sure this reflects the parent container's height */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-wrap: nowrap;
    writing-mode: vertical-rl;
    /* Ensures any overflow is hidden */
}

.left-section p {
    transform: rotate(-180deg);
    font-weight: 800;
    font-size: 100px;
}

.right-section {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-evenly;
    border-radius: 10px;
}

.about p {
    text-align: justify;
    font-size: 20px;
    line-height: 36px;
}

.image-section {
    position: relative;
    top: 0;
    left: 0;
    min-height: 100vh;
    margin-left: 12rem;
    width: calc(100% - 12rem);
}

.section-image {
    vertical-align:top;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.image-section-background-filter {
    position: absolute;
    top: 0;
    left: 0;
    fill: black;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.4;
    z-index: 2;
}
.image-section-text-container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: end;
    z-index: 3;
}
.image-section-text-container svg {
    max-height: 100%; /* Adjust SVG height to fill the container */
    max-width: 100%;
    width: auto;
    object-fit: contain;
    padding: 2rem;
    z-index: 3;
}

.logos-section {
    margin-left: 12rem;
    width: calc(100% - 12rem);
    text-align: center;
    padding: 3rem 0;
}

.logos-section h2 {
    margin-bottom: 2rem;
}

@media screen and (max-width: 768px),
             screen and (max-height: 500px) {
    .main-content {
        background-size: 100%;
        background-position: top;
    }
    .section {
        margin-left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;
    }
    .left-section {
        writing-mode: unset;
        height: unset;
    }
    .left-section p {
        transform: unset;
        font-size: 100px;
    }

    .image-section {
        margin-left: 0;
        width: 100%;
    }
    .logos-section {
        margin-left: 0;
        width: 100%;
    }
}

.logos-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.logos-container a {
    display: flex;
    align-items: center;
    padding: 0.5rem;
}

.logos-container img {
    max-width: 6rem; /* Set a maximum width for the images */
    width: 100%; /* Allow the images to shrink or grow responsively */
    height: auto; /* Maintain the aspect ratio of the images */
    object-fit: contain; /* Ensure images are fully contained within their boxes */
}

