.background-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}


.background-image {
    vertical-align:top;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.5s ease-in-out; /* Płynna zmiana przezroczystości */
}
.background-filter {
    position: absolute;
    top: 0;
    left: 0;
    fill: black;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.4;
    z-index: 2;
}

.background-image.fade-out {
    opacity: 0;
}

.centered-bars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center;
    transform: translate(-30%, -50%) scale(67%, 67%);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    flex-direction: column;
    align-items: center;
    z-index: 3;
}

.centered-bars-selected {
    fill:black;
}

.bar {
    margin: 10px 0;
}

.bar path:hover{
    fill: black;
    transition: fill 1s;
    cursor: pointer;
}

.company-title-slideshow {
    margin-top: 10px;
}