/*@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  font-family: Arial, sans-serif;
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.App {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App > :nth-child(2) {
  flex-grow: 1;
}