.team-container {
    margin-left: 12rem;
    width: calc(100% - 12rem);
    background-image: url('/public/background.svg');
    background-repeat: repeat-y;
    background-size: 100%;
    background-repeat: no-repeat; 
    background-attachment: scroll;
}

.team-section {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 4fr;
}

.team-section-header {
    max-height: fit-content;
    width: 100%; /* Adjust this to your desired width */
     /* Make sure this reflects the parent container's height */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-wrap: nowrap;
    font-weight: 900;
    margin-top: 1rem;
    /* Ensures any overflow is hidden */
}

.team-section-header p {
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
    font-weight: 800;
    font-size: 100px;
}
.team-members {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    max-width: 100%;
    margin-bottom: 25vh;
}
@media screen and (max-width: 1200px){
    .team-members {
        grid-template-columns: repeat(2, 1fr); /* 3 panele w rzędzie */
    }
}

@media screen and (max-width: 900px){
    .team-members {
        grid-template-columns: repeat(1, 1fr); /* 3 panele w rzędzie */
    }
}

@media screen and (max-width: 768px),
             screen and (max-height: 500px) {
    .team-container {
        margin-left: 0;
        width: 100%;
        background-image: url('/public/background.svg');
        background-repeat: repeat-y;
        background-size: 100%;
        background-attachment: scroll;
    }
    .team-section {
        padding-top: 4rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 25vh;
    }
    .team-section-header {
        margin-top: unset;
    }
    .team-section-header p {
        transform: unset;
        writing-mode: unset;
        font-size: 72px;
    }
}

@media screen and (min-width: 1200px) {
    .team-section-header p {
        font-size: 100px;
    }
}