.navbar-container {
    width: 12rem;
    height: 100vh;
    position: fixed;
    z-index: 4;

}
.navbar-container * {
    font-family: "acumin-variable",sans-serif;
    font-variation-settings: 'wght' 500, 'wdth' 100, 'slnt' 0;
}

.navbar {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    transition: background-color 0.3s ease-in-out;
}

@media screen and (max-width: 768px),
             screen and (max-height: 500px) {
    .navbar-container {
        width: 100vw;
        height: 3rem;
        position: fixed;
        z-index: 4;
    }
    .navbar {
        background-color: rgba(255, 255, 255, 1);
        overflow: auto;
    }
}
.navbar-container-clicked {
    width: 100vw;
    height: 100dvh;
}

.navbar:hover {
    background-color: rgba(255, 255, 255, 1);
}

.top-navigation-row {
    display: flex;
    justify-content: space-between;
}

.lang {
    display: flex;
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
    cursor: pointer;
}

.lang_selected {
    font-weight: bold;
}

.nav-links {
    flex-grow: 1;
    font-size: 22px;
    font-weight: bold;
    list-style: none;
}

.nav-links-secondary {
    flex-grow: 1;
    list-style: none;
    font-size: 16px;
    font-weight: normal;
}

.nav-links li {
    height: 2.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

.nav-links li a {
    width: fit-content;
    text-decoration: none;
    color: #333;
    transition: font-size 0.3s;
}

.nav-links li a.active-link {
    font-weight: bolder;
    font-size: 27px;
}

.nav-links li a.active-link-secondary {
    font-weight: bold;
    font-size: 17px;
}

.brand {
    bottom: 50px; /* Pozycjonowanie na dole nawigacji */
    left: 20px;
    text-align: center;
}

.logo-mark {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.logo-mark span {
    display: block;
    width: 50px;
    height: 10px;
    background-color: black;
    margin: 5px 0;
}

.brand p {
    font-size: 16px;
}

.mobile-header {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.mobile-header svg {
    padding: 10px;
    height: 100%;
    width: fit-content;
}
.top-navigation-row svg {
    cursor: pointer;
}