.contact-container {
    padding-top: 1rem;
    margin-left: 12rem;
    width: calc(100% - 12rem);
    background-image: url('/public/background.svg');
    background-size: 100%;
    background-repeat: no-repeat; 
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    flex-direction: column;
}

.contact-content-header {
    max-height: fit-content;
    width: 100%; /* Adjust this to your desired width */
    /* Make sure this reflects the parent container's height */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-wrap: nowrap;
    font-weight: 900;
    /* Ensures any overflow is hidden */
}
.contact-content-header p {
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
    font-weight: 800;
    font-size: 100px;
}
.contact-content {
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    display: flex; /* Umożliwienie horyzontalnego układu */
    flex-direction: column; /* Ułożenie w kolumnie */
    align-items: center; /* Wyśrodkowanie */
    justify-content: stretch;
}
.st4, .st5, .st6 {
    fill: #303031;
}
.contact-content > * {
    font-size: 48px;
}
.contact-links {
    display: flex; /* Umożliwienie horyzontalnego układu */
    gap: 50px; /* Odstęp między ikonami */
    margin-top: 5rem;
    margin-bottom: 1rem; /* Odstęp pod ikonami */
}

.contact-links a {
    color: white; /* Kolor linków */
    text-decoration: none; /* Usunięcie podkreślenia */
}

.contact-links img{
    width: 60px;
    height: 60px;
}
.contact-links svg{
    width: 72px;
    height: 72px;
}

.contact-address-container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    align-items: center;
}
.contact-address-container .contact-bolded {
    font-weight: 800;
}
.contact-address-container svg {
    height: 72px;
    width: 72px;
}
.contact-address-container span {
    text-align: center;
}
.contact-address-container *{
    margin-top: 1rem;
}

.contact-address-container span{
    font-size: 20px;
}

.contact-justified-container {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 768px),
             screen and (max-height: 500px) {
    .contact-container {
        margin-left: 0;
        width: 100%;
        background-image: url('/public/background.svg');
        background-repeat: repeat-y;
        background-size: 100%;
        background-attachment: scroll;
    }
    .contact-container {
        padding-top: 4rem;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .contact-content-header p {
        transform: unset;
        writing-mode: unset;
        font-size: 72px;
        padding-bottom: 2rem;
    }
}

@media screen and (min-width: 1200px) {
    .contact-content-header p {
        font-size: 100px;
    }
}