.carousel-container {
    display: flex; /* Umożliwienie horyzontalnego układu */
    overflow-x: auto; /* Horyzontalne przewijanie */
    width: 100%;
    height: 100vh; /* Wysokość kontenera */
    padding: 0 20px;
    scroll-snap-type: x mandatory; /* Umożliwienie snapowania do zdjęć */
}

.carousel-image-wrapper {
    position: relative; /* Umożliwienie pozycjonowania napisu */
    min-width: 90vw; /* Minimalna szerokość zdjęcia to 80% szerokości ekranu */
    height: 100%; /* Wysokość kontenera */
    margin-right: 20px; /* Odstęp między zdjęciami */
    display: flex; /* Umożliwienie elastycznego układu */
    align-items: center; /* Wyśrodkowanie w pionie */
}

.carousel-image {
    width: 100%; /* Zdjęcie wypełnia cały dostępny obszar */
    height: 100%; /* Wysokość zdjęcia na 100% wysokości kontenera */
    object-fit: cover; /* Zachowanie proporcji zdjęcia */
    scroll-snap-align: start; /* Umożliwienie wyrównania zdjęcia przy przewijaniu */
}

.carousel-text-overlay {
    position: absolute; /* Umożliwienie swobodnego pozycjonowania napisu */
    right: 20px; /* Odstęp od prawej krawędzi */
    bottom: 20px;
    color: white; /* Kolor tekstu */
    font-size: 60px; /* Rozmiar czcionki */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Cień tekstu dla lepszej widoczności */
    writing-mode: vertical-rl; /* Obrót tekstu do pionu */
    transform: rotate(180deg); /* Obrót tekstu w drugą stronę */
    display: flex;
    flex-flow: row nowrap;
    height: fit-content;
    max-height: calc(100vh - 20px);
    align-items: center;
}

.carousel-text-overlay svg {
    flex-shrink: 0;
    margin-right: 0.75rem;
    margin-bottom: 1rem;
}

.carousel-text-overlay span {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.75rem;
    margin-bottom: 1rem;
}