.projects-container {
    margin-left: 12rem;
    width: calc(100% - 12rem);
    background-color: #e8e8e8;
    background-image: url('/public/background.svg');
    background-repeat: repeat-y;
    background-size: 100%;
    background-attachment: scroll;
}

.projects-container * {
    font-family: "acumin-variable", sans-serif;
    font-variation-settings: 'wght' 300, 'wdth' 100, 'slnt' 0;
}

/* Kontener na panele */
.panel-section {
    display: grid;
    width: 100%;
    column-gap: 1rem; /* Przerwa między panelami */
    grid-template-columns: repeat(3, 1fr); /* 3 panele w rzędzie */
    margin-bottom: 25vh;
}

@media screen and (max-width: 1200px) {
    .panel-section {
        grid-template-columns: repeat(2, 1fr); /* 3 panele w rzędzie */
    }
}

@media screen and (max-width: 900px) {
    .panel-section {
        grid-template-columns: repeat(1, 1fr); /* 3 panele w rzędzie */
    }
}

@media screen and (max-width: 768px),
             screen and (max-height: 500px) {
    .projects-container {
        margin-left: 0;
        width: 100%;
        padding-top: 3rem;
    }
}